import React from 'react';

export const ContactUs = () => {
    return (
        <div className="contact-box">
            <div className="pricing-faq mt-2">
                <h2 className='text-center text-white'>Contact us at: info@thegofolio.com</h2>
            </div>
            {/* <div className="row mt-3">
                <div className="col-sm-8 mx-auto">
                    <input className="form-control" type="text" placeholder="Name" aria-label="Name" />
                    <div className="d-flex">
                        <input className="form-control me-2 my-3" type="email" placeholder="Email" aria-label="email" />
                        <input className="form-control ms-2 my-3" type="text" placeholder="Phone number" aria-label="number" />
                    </div>
                    <textarea className="form-control" placeholder='Your message' id="exampleFormControlTextarea1" rows="5"></textarea>
                    <button className='btn mt-3'>SEND</button>
                </div>
            </div> */}
        </div>
    )
};