import React, { useEffect, useState } from 'react';
import Nav from '../common/Nav';
import Modal from 'react-bootstrap/Modal';
import logo from '../../dist/images/GOFOLIO_NAVY.svg';
import { PortfolioVirtListFiltered } from '../portfolio/PortfolioVirtListFiltered';
import { BoxPlaceHolder } from '../portfolio/Box';
import { CompareResult } from '../portfolio/CompareResult';
import Spotlight from '../portfolio/Spotlight';
import { _examplePortfolioData } from '../common/data';
import { pollForPortfolio, getPortfolioData } from '../common/methods';
import { getPortfolioRun, removePortfolio, refreshPortfolioRun } from '../../api/api';
import { useNavigate } from "react-router-dom";

const MyPortfolios = (props) => {
  const navigate = useNavigate();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deletePortfolioErrMsg, setDeletePortfolioErrMsg] = useState('');
  const [showPortfolioCompare, setShowPortfolioCompare] = useState(false);

  const [spotlightPortfolio, setSpotlightPortfolio] = useState({});
  const [portfolioCompare1, setPortfolioCompare1] = useState(null);
  const [portfolioCompare2, setPortfolioCompare2] = useState(null);
  const [portfolioCompare3, setPortfolioCompare3] = useState(null);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});

  const checkProps = () => {
    const l = props.topPortfoliosSelected.length;
    if (l > 0) {
      setPortfolioCompare1({
        ...props.topPortfoliosSelected[0],
        isLoading: true,
      });
      getPortfolioData(props.topPortfoliosSelected[0]['id'], props.portfolios, props.portfolioDataMap, props.handleUpdatePortfolioData, navigate)
        .then((res) => {
          setPortfolioCompare1(res);
        });
      if (l > 1) {
        setPortfolioCompare2({
          ...props.topPortfoliosSelected[1],
          isLoading: true,
        });
        getPortfolioData(props.topPortfoliosSelected[1]['id'], props.portfolios, props.portfolioDataMap, props.handleUpdatePortfolioData, navigate)
          .then((res) => {
            setPortfolioCompare2(res);
          });
      }
      if (l > 2) {
        setPortfolioCompare3({
          ...props.topPortfoliosSelected[2],
          isLoading: true,
        });
        getPortfolioData(props.topPortfoliosSelected[2]['id'], props.portfolios, props.portfolioDataMap, props.handleUpdatePortfolioData, navigate)
          .then((res) => {
            setPortfolioCompare3(res);
          });
      }
      setShowPortfolioCompare(true);
    }
    if (props.spotlightPortfolio) {
      setSpotlightPortfolio(props.spotlightPortfolio);
      if (props.spotlightPortfolio.id === -1 || props.spotlightPortfolio.status === 'Complete') {
        return;
      }
      return pollForPortfolio(props.spotlightPortfolio.id, props.portfolios, navigate).then((res) => {
        setSpotlightPortfolio(res);
        // replace the portfolio data with the refreshed data        
        props.handleUpdatePortfolioData(res.id, res);
      });
    }
  };
  const toggleShowPortfolioCompare = () => {
    if (showPortfolioCompare) {
      setShowPortfolioCompare(false);
    } else {
      setShowPortfolioCompare(true);
    }
  };
  const handleRefresh = async (id, src = 'spotlight', justReload = false) => {
    var res;
    if (!justReload) {
      res = await refreshPortfolioRun(id);
    }
    if (justReload || res.status === 200) {
      res = await getPortfolioRun(id);
      if (res.status === 200) {
        // portfolio ready, update data everywhere
        props.handleUpdatePortfolioData(id, res.data);
        if (src === "spotlight") {
          setSpotlightPortfolio(res.data);
        } else if (src === "compare1") {
          setPortfolioCompare1(res.data);
        } else if (src === "compare2") {
          setPortfolioCompare2(res.data);
        } else if (src === "compare3") {
          setPortfolioCompare3(res.data);
        }
        return;
      } else if (res.status === 202) {
        // portfolio not ready, update status everywhere
        const idx = props.portfolios.findIndex((obj => obj.id === id));
        const p = props.portfolios[idx];
        p['status'] = "Processing";
        props.handleUpdatePortfolioData(id, p);
        if (src === "spotlight") {
          setSpotlightPortfolio(prevState => ({
            ...prevState,
            status: "Processing",
          }));
        } else if (src === "compare1") {
          setPortfolioCompare1(prevState => ({
            ...prevState,
            status: "Processing",
          }));
        } else if (src === "compare2") {
          setPortfolioCompare2(prevState => ({
            ...prevState,
            status: "Processing",
          }));
        } else if (src === "compare3") {
          setPortfolioCompare3(prevState => ({
            ...prevState,
            status: "Processing",
          }));
        }
        let pData = await pollForPortfolio(id, props.portfolios, navigate);
        if (src === "spotlight") {
          setSpotlightPortfolio(pData);
        } else if (src === "compare1") {
          setPortfolioCompare1(pData);
        } else if (src === "compare2") {
          setPortfolioCompare2(pData);
        } else if (src === "compare3") {
          setPortfolioCompare3(pData);
        }
      } else if (res.status === 403 || res.status === 404) {
        navigate("/login");
      } else {
        // portfolio failsauce, update data everywhere 
        const idx = props.portfolios.findIndex((obj => obj.id === id));
        const p = props.portfolio[idx];
        p['isErr'] = true;
        p['msg'] = res.data.msg;
        props.handleUpdatePortfolioData(id, p);
        if (src === "spotlight") {
          setSpotlightPortfolio(prevState => ({
            ...prevState,
            isErr: true,
            msg: res.data.msg,
          }));
        } else if (src === "compare1") {
          setPortfolioCompare1(prevState => ({
            ...prevState,
            isErr: true,
            msg: res.data.msg,
          }));
        } else if (src === "compare2") {
          setPortfolioCompare2(prevState => ({
            ...prevState,
            isErr: true,
            msg: res.data.msg,
          }));
        } else if (src === "compare3") {
          setPortfolioCompare3(prevState => ({
            ...prevState,
            isErr: true,
            msg: res.data.msg,
          }));
        }
        return;
      }
    } else if (res.status === 202) {
      // portfolio not ready, update status everywhere
      const idx = props.portfolios.findIndex((obj => obj.id === id));
      const p = props.portfolios[idx];
      p['status'] = "Processing";
      props.handleUpdatePortfolioData(id, p);
      if (src === "spotlight") {
        setSpotlightPortfolio(prevState => ({
          ...prevState,
          status: "Processing",
        }));
      } else if (src === "compare1") {
        setPortfolioCompare1(prevState => ({
          ...prevState,
          status: "Processing",
        }));
      } else if (src === "compare2") {
        setPortfolioCompare2(prevState => ({
          ...prevState,
          status: "Processing",
        }));
      } else if (src === "compare3") {
        setPortfolioCompare3(prevState => ({
          ...prevState,
          status: "Processing",
        }));
      }
      let pData = await pollForPortfolio(id, props.portfolios, navigate);
      if (src === "spotlight") {
        setSpotlightPortfolio(pData);
      } else if (src === "compare1") {
        setPortfolioCompare1(pData);
      } else if (src === "compare2") {
        setPortfolioCompare2(pData);
      } else if (src === "compare3") {
        setPortfolioCompare3(pData);
      }
    } else if (res.status === 403 || res.status === 404) {
      navigate("/login");
    } else {
      // portfolio failsauce, update data everywhere 
      const idx = props.portfolios.findIndex((obj => obj.id === id));
      const p = props.portfolios[idx];
      p['isErr'] = true;
      p['msg'] = res.data.msg;
      props.handleUpdatePortfolioData(id, p);
      if (src === "spotlight") {
        setSpotlightPortfolio(prevState => ({
          ...prevState,
          isErr: true,
          msg: res.data.msg,
        }));
      } else if (src === "compare1") {
        setPortfolioCompare1(prevState => ({
          ...prevState,
          isErr: true,
          msg: res.data.msg,
        }));
      } else if (src === "compare2") {
        setPortfolioCompare2(prevState => ({
          ...prevState,
          isErr: true,
          msg: res.data.msg,
        }));
      } else if (src === "compare3") {
        setPortfolioCompare3(prevState => ({
          ...prevState,
          isErr: true,
          msg: res.data.msg,
        }));
      }
      return;
    }
  };
  const deletePortfolio = (id, confirmed = false) => {
    if (!confirmed || id !== confirmDeleteData['id']) {
      let ret = props.portfolios.find((item) => {
        return item.id === id
      });
      if (!ret) {
        setDeletePortfolioErrMsg(`Invalid id: ${id}`);
        return;
      }
      setConfirmDeleteData(ret);
      setShowConfirmDeleteModal(true);
      return;
    }
    if (id === -1) {
      // handle if user wants to remove the example portfolio
      return;
    }
    return removePortfolio(id).then((res) => {
      if (res.status === 200) {
        setShowConfirmDeleteModal(false);
        props.handleDeletePortfolio(id);
        // if this is the only portfolio
        if (props.portfolios.length === 1 && props.portfolios[0]['id'] === id) {
          if (id === -1) {
            // handle if user wants to remove the example portfolio
            return;
          }
        }
        removePortfolioCompare(id);
        setSpotlightPortfolio(_examplePortfolioData);
      } else if (res.status === 403 || res.status === 404) {
        navigate("/login");
      } else {
        setDeletePortfolioErrMsg(res.data.msg);
      }
    })
  };
  const handlePortfolioClick = (id) => {
    return getPortfolioData(id, props.portfolios, props.portfolioDataMap, props.handleUpdatePortfolioData, navigate).then((data) => {
      if (showPortfolioCompare) {
        if (!portfolioCompare1) {
          setPortfolioCompare1(data);
          if (!!data.isErr && data.status === "Processing") {
            return pollForPortfolio(id, props.portfolios, navigate).then((res) => {
              setPortfolioCompare1(res);
            });
          }
        } else if (!portfolioCompare2) {
          setPortfolioCompare2(data);
          if (!!data.isErr && data.status === "Processing") {
            return pollForPortfolio(id, props.portfolios, navigate).then((res) => {
              setPortfolioCompare2(res);
            });
          }
        } else {
          setPortfolioCompare3(data);
          if (!!data.isErr && data.status === "Processing") {
            return pollForPortfolio(id, props.portfolios, navigate).then((res) => {
              setPortfolioCompare3(res);
            });
          }
        }
      } else {
        setSpotlightPortfolio(data);
        if (data.status === "Processing") {
          return pollForPortfolio(id, props.portfolios, navigate).then((res) => {
            // console.log(`setSpotlightPortfolio8`);
            setSpotlightPortfolio(res);
          });
        }
      }
    });
  };
  const removePortfolioCompare = (id) => {
    if (portfolioCompare1 && portfolioCompare1['id'] === id) {
      setPortfolioCompare1(null);
    } else if (portfolioCompare2 && portfolioCompare2['id'] === id) {
      setPortfolioCompare2(null);
    } else if (portfolioCompare3 && portfolioCompare3['id'] === id) {
      setPortfolioCompare3(null);
    }
  };
  useEffect(() => { checkProps(); }, []);

  useEffect(() => {
    const fullHeight = () => {
      const elements = document.querySelectorAll('.js-fullheight');
      elements.forEach((element) => {
        element.style.height = window.innerHeight + 'px';
      });
    };

    const handleResize = () => {
      fullHeight();
    };

    window.addEventListener('resize', handleResize);

    const sidebarToggle = () => {
      const sidebar = document.getElementById('sidebar');
      sidebar.classList.toggle('active');
    };

    const sidebarCollapse = document.getElementById('sidebarCollapse');
    sidebarCollapse.addEventListener('click', sidebarToggle);

    // Clean up event listeners
    return () => {
      window.removeEventListener('resize', handleResize);
      sidebarCollapse.removeEventListener('click', sidebarToggle);
    };
  }, []);

  return (
    <>
      <Nav heading="My Portfolios" setPage={props.setPage} page={props.page} name={props.name} />
      <PortfolioVirtListFiltered portfolios={props.portfolios} portfolioClickHook={handlePortfolioClick} portfolioDeleteHook={deletePortfolio} />
      {deletePortfolioErrMsg ? <div className="row justify-content-center text-center my-5">
        <div className="col"><p className="error-msg">{deletePortfolioErrMsg}</p></div>
      </div> : null}
      <div className="row justify-content-center text-center mb-1">
        <div className="col-sm-12 col-md-6" >
          <button className="btn button-light" onClick={() => props.setPage('portfolioForm')}>CREATE NEW CALCULATION</button>
        </div>
        <div className="col" >
          <button className="btn button-dark" onClick={toggleShowPortfolioCompare}>{showPortfolioCompare ? "SHOW SINGLE PORTFOLIO" : "COMPARE PORTFOLIOS"}</button>
        </div>
      </div>

      {showPortfolioCompare ?
        <div className="box-container my-4">
          <div className='row'>
            {portfolioCompare1 ? <CompareResult data={portfolioCompare1} removeHook={removePortfolioCompare} handleRefresh={handleRefresh} src="compare1"></CompareResult> : <BoxPlaceHolder text="Choose a Portfolio from the list above to compare"></BoxPlaceHolder>}
            {portfolioCompare2 ? <CompareResult data={portfolioCompare2} removeHook={removePortfolioCompare} handleRefresh={handleRefresh} src="compare2"></CompareResult> : <BoxPlaceHolder text="Choose a Portfolio from the list above to compare"></BoxPlaceHolder>}
            {portfolioCompare3 ? <CompareResult data={portfolioCompare3} removeHook={removePortfolioCompare} handleRefresh={handleRefresh} src="compare3"></CompareResult> : <BoxPlaceHolder text="Choose a Portfolio from the list above to compare"></BoxPlaceHolder>}
          </div>
        </div> : Object.keys(spotlightPortfolio).length === 0 ?
          <div className="box-container my-4">
            <div className='row justify-content-center'>
              <BoxPlaceHolder text="Choose a Portfolio from the list above to review"></BoxPlaceHolder>
            </div>
          </div> : <Spotlight data={spotlightPortfolio} handleRefresh={handleRefresh} src={"spotlight"} ></Spotlight>
      }

      <Modal show={showConfirmDeleteModal}>
        <div className="text-center py-3">
          <img src={logo} width="228.996px" height="93.9961" alt='' />
        </div>

        <div className="card add-portfolio-card mx-auto w-75 w-xs-100 mb-2">
          <div className="card-body mb-2">
            <div className="container text-center px-1 pt-1">

              {confirmDeleteData['id'] === -1 && props.portfolios.length === 1 ?
                <><div className="text-center">
                  <p>Create a new portfolio and then you can delete this one :)</p>
                </div>
                  <div className="row text-center">
                    <div className="col mt-3">
                      <button className="btn button-dark" onClick={() => setShowConfirmDeleteModal(false)}>CANCEL</button>
                    </div>
                  </div> </> : <> <div className="text-center">
                    <p>Are you sure you want to delete this portfolio?</p>
                  </div>
                  <div className="text-center">
                    <p>{confirmDeleteData['name']}</p>
                  </div>

                  {deletePortfolioErrMsg ? <div className="text-center">
                    <p className="error-msg">{deletePortfolioErrMsg}</p>
                  </div> : null}

                  <div className="row text-center">
                    <div className="col mt-3">
                      <button className="btn button-dark" onClick={() => setShowConfirmDeleteModal(false)}>CANCEL</button>
                    </div>
                    <div className="col mt-3">
                      <button type="button" className="btn button-ol-dark" onClick={() => deletePortfolio(confirmDeleteData['id'], true)}>YES, DELETE</button>
                    </div>
                  </div></>}


            </div>
          </div>

        </div>
      </Modal>

    </>
  )
}

export default MyPortfolios


