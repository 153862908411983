import React, { useEffect } from 'react';
import logo from '../../dist/images/GOFOLIO_WHITE.svg'
import { useLocation, useNavigate} from "react-router-dom";
import dropArrow from '../../dist/icons/dropdown_arrowx2.svg';
import overview from '../../dist/icons/GoFolio_OVERVIEW.svg';
import profile from '../../dist/icons/GoFolio_MY_ACCOUNT.svg';
import security from '../../dist/icons/GoFolio_MY_SECURITIES.svg';
import portfolio from '../../dist/icons/GoFolio_PORTFOLIO.svg';
import help from '../../dist/icons/GoFolio_HELP.svg';
import logout from '../../dist/icons/GoFolio_LOGOUT.svg';
import { Link } from 'react-router-dom';
import { UserData } from '../account/UserData'

const MyAccount = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const fullHeight = () => {
      const elements = document.querySelectorAll('.js-fullheight');
      elements.forEach((element) => {
        element.style.height = window.innerHeight + 'px';
      });
    };

    const handleResize = () => {
      fullHeight();
    };

    window.addEventListener('resize', handleResize);

    const sidebarToggle = () => {
      const sidebar = document.getElementById('sidebar');
      sidebar.classList.toggle('active');
    };

    const sidebarCollapse = document.getElementById('sidebarCollapse');
    sidebarCollapse.addEventListener('click', sidebarToggle);

    // Clean up event listeners
    return () => {
      window.removeEventListener('resize', handleResize);
      sidebarCollapse.removeEventListener('click', sidebarToggle);
    };
  }, []);
  return (
    <>
      <div className="d-block d-md-flex flex-wrap align-items-center py-2 py-md-4 py-lg-4 py-xl-4" style={{ margin: '0 20px' }}>
        <div className="mb-1 mb-md-0 text-center text-md-start">
          <Link className="nav-link ms-0" style={{ borderBottom: 'none' }}>
            <span className="heading">
              My Account
            </span>
          </Link>
        </div>
        <div className="ms-auto ">
          <div className="nav-link text-end" tabIndex="-1" aria-disabled="true" style={{ borderBottom: 'none' }}>
            <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
              <span className="ms-2">{props.name}</span>
              <span className="nav-link B me-0">
                <p>{props.name !== undefined && props.name.length > 0 ? props.name[0].toUpperCase() : 'Anonymous'}</p>
              </span>
              <div className="btn-group">
                <Link className="dropdown-toggle nav-link me-0" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ borderBottom: 'none' }}>
                  <img src={dropArrow} alt='' />
                </Link>
                <ul className="dropdown-menu dropdown-menu-lg-end mt-2 me-1">
                  <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile nav */}
      <div
        className="offcanvas  offcanvas-start"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header" >
          <h5 className="offcanvas-title text-white" id="offcanvasExampleLabel">
            Dashboard
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body p-0">
          <ul className="nav flex-column">
            <li className={location.pathname === "/dashboard" ? "active-link" : ""}>
              <Link to="/app">
                <span><img className='fa' src={overview} width={28} alt='' /></span>
                <span className='colp'>Dashboard</span>
              </Link>
            </li>
            <li className={location.pathname === "/account" ? "active-link" : ""}>
              <Link to="/account">
                <span><img className='fa' src={profile} width={28} alt='' /></span>
                <span className='colp'>My Account</span>
              </Link>
            </li>
            <li className={location.pathname === "/" ? "active-link" : ""}>
              <Link to="/" >
                <span><img className='fa' src={portfolio} width={28} alt='' /></span>
                <span className='colp'>My Portfolios</span>
              </Link>
            </li>
            <li className={location.pathname === "/securities" ? "active-link" : ""}>
              <Link to="/securities">
                <span><img className='fa' src={security} width={28} alt='' /></span>
                <span className='colp'>My Securities</span>
              </Link>
            </li>
            <li className={location.pathname === "/help" ? "active-link" : ""}>
              <Link to="/help">
                <span><img className='fa' src={help} width={28} alt='' /></span>
                <span className='colp'>Help</span>
              </Link>
            </li>
            <li className={location.pathname === "/logout" ? "active-link" : ""}>
              <Link onClick={() => navigate('/logout')}>
                <span><img className='fa' src={logout} width={28} alt='' /></span>
                <span className='colp'>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='account-container'>
            <div className="account-topbar">
                <div className="img-box">
                    <div className="img-box-inner">
                        <img src={logo} alt=''/>
                    </div>
                </div>
                <div className="details-box">
                    <div className="details-box-inner">
                        <div className="profile-img">
                            <h1>{props.name !== undefined && props.name.length > 0 ? props.name[0].toUpperCase() : 'Anonymous'}</h1>
                        </div>
                        <h2>{props.name}</h2>
                    </div>
                </div>
            </div>
        </div>
      <UserData />
    </>
  )
}

export default MyAccount