import React from 'react'
import Navbar from '../common/Navbar'
import { TermsText } from '../common/TermsDisclosures';


const TOS = () => {
    return (
        <>
            <Navbar page={'tos'} />
            <TermsText></TermsText>
        </>
    )
}

export default TOS