import React from 'react';
import logo from '../../dist/images/GOFOLIO_WHITE.svg'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";


// always shown at top of page, regardless of user logged in or not

const Navbar = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <nav className="navbar navbar-expand-lg navbar-light top-nav">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/" style={{ border: 'none' }}>
                    <img src={logo} alt='' />
                </Link>
                <button
                    className="btn btn-dark d-inline-block d-lg-none ml-auto border-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent1"
                    aria-controls="navbarSupportedContent1"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    style={{ backgroundColor: '#026773' }}
                >
                    <i className="fa fa-bars" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                    <ul className="nav navbar-nav ms-auto">
                        <AuthenticatedTemplate>
                            <li className="nav-item">
                                <Link to="/app" className={location.pathname === "/app" ? "nav-link active-page" : "nav-link"}>
                                    Home
                                </Link>
                            </li> </AuthenticatedTemplate>
                        <li className="nav-item">
                            <Link to="/pricing" className={location.pathname === "/pricing" ? "nav-link active-page" : "nav-link"}>
                                Pricing
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/faq" className={location.pathname === "/faq" ? "nav-link active-page" : "nav-link"}>
                                FAQ
                            </Link>
                        </li>

                        <li className="nav-item my-auto">
                            <AuthenticatedTemplate><button
                                className="btn ms-2 button-one"
                                onClick={() => props.setPage === undefined ? navigate('/app') : props.setPage('portfolioForm')}
                            >
                                CREATE NEW CALCULATION
                            </button> </AuthenticatedTemplate>
                            <UnauthenticatedTemplate> <button
                                className="btn ms-2 button-login"
                                onClick={() => navigate('/login')}
                            >
                                LOGIN / REGISTER
                            </button>
                            </UnauthenticatedTemplate>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar