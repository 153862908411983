import React from 'react';
import Navbar from '../common/Navbar';
import { ContactUs } from '../common/ContactUs';

const Pricing = () => {
    return (
        <>
            <Navbar page={'pricing'} />
            <div className='pricing-container mb-5 pb-5'>
                <h1 className='text-center text-uppercase'>A Simple pricing model</h1>
                <p className='mx-4 text-center' style={{ color: '#012e40' }}>Some software solutions will charge thousands of dollars per year!</p>
                <p className='mx-4 text-center' style={{ color: '#012e40' }}>Our goal at Gofolio is to provide these insights and solutions at the lowest sustainable cost that we can to provide data driven analytics for our customers.</p>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-lg-4 col-sm-12">
                        <div className="pricing-inner-box text-center">
                            <h1>
                                FREE
                            </h1>
                            <p className='mx-5 px-4 mt-4 text'>Create 50 portfolios a day, for free! </p>
                            <p className='mx-5 px-4 mt-4 text'>Check back soon for premium features as they become available. </p>
                        </div>
                    </div>
                </div>
                <ContactUs></ContactUs>
            </div>
        </>
    )
}

export default Pricing