import React, { useState, useEffect } from 'react';

// should get rendered when idle or remaining session is < 60
export const ExtendSessionModal = ({ refreshTokenErrMsg, canRefresh, isIdle, showExtendSessionModalTime, handleLogOut, handleStayLoggedIn }) => {
    const [remainingTime, setRemainingTime] = useState(showExtendSessionModalTime);

    useEffect(() => {
        // logout when we reach 0
        if (remainingTime <= 0) {
            handleLogOut();
        }
    
        // save intervalId to clear the interval when the component re-renders
        const intervalId = setInterval(() => {
            setRemainingTime(remainingTime - 1);
        }, 1000);
    
        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add remainingTime as a dependency to re-rerun the effect when we update it
      }, [remainingTime]);

    const secondsToMinutesAndSeconds = (seconds) => {
        let minutes = ~~(seconds / 60);
        let s = Math.round(seconds % 60);
        if (minutes <= 0) {
            return `${s} seconds`;
        }
        return `${minutes} minute${minutes > 1 ? 's' : ''} and ${s} seconds`;
    };
    return (
        <div className="card add-portfolio-card mx-auto w-75 w-xs-100 mb-2">
            <div className="card-body mb-2">
                <div className="container text-center px-1 pt-1">
                    <h2>Timeout Warning</h2>
                    <p>{isIdle ? 'You are about to be logged out due to inactivity.' : canRefresh ? 'Your session is expiring but can be extended.' : 'Your session is expiring and you will need to login again.'}</p>
                    <p>Time remaining:</p>
                    <p>{secondsToMinutesAndSeconds(remainingTime)}</p>
                    {refreshTokenErrMsg ? <div className="text-center">
                        <p className="error-msg">{refreshTokenErrMsg}</p>
                    </div> : null}
                    <div className="row">
                        {isIdle ? <>
                            <div className="col">
                                <button className="btn button-dark" onClick={handleLogOut}>
                                    Logout
                                </button>
                            </div>
                            <div className="col">
                                <button className="btn button-light" onClick={() => handleStayLoggedIn(false)}>
                                    Stay Logged In
                                </button>
                            </div>
                        </> : canRefresh ? <>
                            <div className="col">
                                <button className="btn button-dark" onClick={handleLogOut}>
                                    Logout
                                </button>
                            </div>
                            <div className="col">
                                <button className="btn button-light" onClick={() => handleStayLoggedIn(true)}>
                                    Continue Session
                                </button>
                            </div>
                        </> :
                            <div className="col">
                                <button className="btn button-dark" onClick={handleLogOut}>
                                    Re-authenticate
                                </button>
                            </div>
                        }
                    </div>

                </div>
            </div>

        </div>
    )
}