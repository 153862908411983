import React from 'react'
import Navbar from '../common/Navbar'
import { FAQAccordion } from '../common/FAQAccordion';


const FAQ = () => {
    return (
        <>
            <Navbar page={'faq'} />
            <FAQAccordion></FAQAccordion>
        </>
    )
}

export default FAQ