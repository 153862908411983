import React from 'react'
import { TermsText } from "./TermsDisclosures";
import { ContactUs } from './ContactUs';

export const FAQAccordion = () => {
    return (
        <div className='pricing-container mb-5 pb-5'>
            <div className="pricing-faq mt-2">
                <h2 className='text-center text-white'>FREQUENTLY ASKED QUESTIONS</h2>
            </div>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                        >
                            Does Gofolio offer investment advice?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            No, Gofolio is a research tool that provides insights into user-selected portfolios. It does not offer investment advice, recommendations, or strategies. We encourage users to consult a financial professional for personalized advice. Gofolio is not a Registered Investment Advisor (RIA) or Broker-Dealer (BD) and is not affiliated with any financial regulatory bodies. All investments involve risk, including the potential loss of principal.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                        >
                            What is The Gofolio?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            The Gofolio is a portfolio analytics tool that offers statistical insights, including performance, beta, standard deviation, and more, for user-generated portfolios.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                        >
                            What are beta and standard deviation?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            Beta measures a portfolio's volatility compared to a benchmark, in this case, the S&P 500 ETF (SPY). Standard deviation shows how much a portfolio's returns vary from its average. A higher beta or standard deviation indicates more volatility, but not necessarily higher returns.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                        >
                            How do I interpret the Gofolio data?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            We've made Gofolio's data easy to interpret. Darker green means lower beta and/or standard deviation, while darker red indicates higher levels of both. Grey represents neutrality. A higher beta or standard deviation doesn't imply better returns; it highlights volatility and risk in the portfolio.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive"
                            aria-expanded="false"
                            aria-controls="flush-collapseFive"
                        >
                            Will Gofolio create a portfolio for me?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            No, Gofolio does not create portfolios or provide investment advice. It offers statistical insights into portfolios you generate yourself.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSix"
                            aria-expanded="false"
                            aria-controls="flush-collapseSix"
                        >
                            Is Gofolio free?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            Yes, Gofolio is currently free for retail users, supported by ads. Keep an eye out for future updates, including enhanced versions for both retail and professional users.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSeven"
                            aria-expanded="false"
                            aria-controls="flush-collapseSeven"
                        >
                            What is the basis for Gofolio's calculations?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            Gofolio uses the S&P 500 ETF (SPY) as a benchmark. It calculates results based on user inputs or applies Monte Carlo analysis to determine the lowest beta and highest risk-adjusted allocations.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEight"
                            aria-expanded="false"
                            aria-controls="flush-collapseEight"
                        >
                            Why are my security allocations limited to a minimum of 5% and a maximum of 50%?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            To encourage diversification, Gofolio sets boundaries of a 5% minimum and 50% maximum allocation per security. When generating allocations, Gofolio ranks securities based on the desired outcome, optimizing for those that align best with your goals.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseNine"
                            aria-expanded="false"
                            aria-controls="flush-collapseNine"
                        >
                            Can Gofolio be used for investment services?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            No, Gofolio does not offer investment services or advice. It provides statistical data based on past performance and is meant for educational and informational purposes, as outlined in the Terms of Service.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTen"
                            aria-expanded="false"
                            aria-controls="flush-collapseTen"
                        >
                            How frequently is the data on Gofolio updated?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            Gofolio updates its data on a weekly basis, reflecting the most current market information for stocks, ETFs, and mutual funds. Refer to the Data as of Date at the bottom of each portfolio calculation for the specific date the weekly data was pulled from.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEleven"
                            aria-expanded="false"
                            aria-controls="flush-collapseEleven"
                        >
                            Is Gofolio suitable for beginners?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            Yes, Gofolio is designed to be user-friendly for all levels of investors. While we provide advanced metrics, we also aim to make the data easy to interpret for those who are new to investing. However, for personalized advice, we recommend consulting a financial professional.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwelve"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwelve"
                        >
                            Does Gofolio have a mobile app?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            At this time, Gofolio is only available as a web platform, but we are exploring the possibility of a mobile app in future updates.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThirteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseThirteen"
                        >
                            Can I export my portfolio data from Gofolio?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            Currently, Gofolio does not offer an export function for portfolio data, but we are working on adding this feature in future updates.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFourteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseFourteen"
                        >
                            Can I collaborate with others on portfolio analysis?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            At this time, Gofolio is designed for individual use, and there is no feature to share or collaborate on portfolios directly through the platform.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFifteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseFifteen"
                        >
                            How do I contact Gofolio?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            Send us an email at info@thegofolio.com
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSixteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseSixteen"
                        >
                            How do I reset my password?
                        </button>
                    </h2>
                    <div
                        id="flush-collapseSixteen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body">
                            On The Gofolio login page, click the 'Forgot Your Password?' link.
                        </div>
                    </div>
                </div>
            </div>
            <div className="pricing-faq mt-2">
                <h2 className='text-center text-white'>TERMS & DISCLOSURES</h2>
            </div>
            <TermsText></TermsText>
            <ContactUs></ContactUs>
        </div>
    )
}
