import React from 'react';
import Navbar from '../common/Navbar';
import homeHero from '../../dist/images/homeHero.png'
import how1 from '../../dist/icons/ICONS_WHITE_STOCK.svg';
import sideCurve from '../../dist/icons/sideCurve.svg'
import footerLogo from '../../dist/images/GOFOLIO_WHITE.svg';
import mail from '../../dist/icons/mail.svg';
import facebook from '../../dist/icons/facebook.svg';
import instagram from '../../dist/icons/instagram.svg';
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar page={'home'} />
      <div className="wrapper d-flex align-items-stretch">
        <div className="content">

          <div className="home-hero">
            <div className="home-hero-inner-box">
              <div className="container h-100">
                <div className="row h-100 align-items-center">
                  <div className="col-12 col-md-6">
                    <div className="welcome-text">
                      <h2>
                        Welcome to <br />
                        the GoFolio
                      </h2>
                      <p>
                        The industry's most comprehensive and versatile
                      </p>
                      <p>
                        portfolio analytics calculator
                      </p>
                      <button className='btn mt-4' onClick={() => navigate('/login')}>GET STARTED</button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="welcome-thumbnail">
                      <img
                        src={homeHero}
                        alt=""
                        width={500}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='how-it-works text-center'>
            <div className="top-text">
              <h3 className='mb-2 mt-3 pt-4 pb-1'>HOW IT WORKS</h3>
              <h2 className='pt-4 pb-3 text-center' style={{ fontSize: '44px' }}>BUILT FOR BOTH INDUSTRY PROFESSIONALS AND RETAIL INVESTORS</h2>
              <p className='mt-2'>The Gofolio makes assessing portfolio risk simple and easy to use with our own proprietary software. Whether you are an advisor looking to track a model portfolio, prepare a visual aid for client presentations, or a retail investor managing your own portfolio looking for enhanced analytics, The Gofolio is your go to solution, all at the industry's most competitive pricing structure.
              </p>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="how-icons">
                  <div className="icon">
                    <img src={how1} alt="" width={45} />
                  </div>
                </div>
                <p className='mb-0 py-2 mt-2'>
                  COLLECT DATA
                </p>
                <p>
                  Gofolio analyzes trailing 10 year price data for securities selected by you.
                </p>
              </div>
              <div className="col-12 col-md-4">
                <div className="how-icons">
                  <div className="icon">
                    <img src={how1} alt="" width={45} />
                  </div>
                </div>
                <p className='mb-0 py-2 mt-2'>
                  FAST RESULTS
                </p>
                <p>
                  Gofolio will instantly calculates beta, risk adjusted return, and portfolio return based on the inputs of the user, or will generate a model allocation that maximizes the desired metric.
                </p>
              </div>
              <div className="col-12 col-md-4">
                <div className="how-icons">
                  <div className="icon">
                    <img src={how1} alt="" width={45} />
                  </div>
                </div>
                <p className='mb-0 py-2 mt-2'>
                  PERSONALIZED EXPERIENCE
                </p>
                <p>
                  Save your favorite securities and model portfolios (based on subscription level) to review and assess at a later date.
                </p>
              </div>
            </div>
            <div className="border-box">
            </div>
            <div className="top-text pt-3">
              <h2 style={{ fontSize: '44px' }}>SIMPLE TO GET STARTED</h2>
            </div>
            <div className="row py-1">
              <div className="col-12 col-md-4">
                <div className="how-icons">
                  <div className="icon">
                    <img src={how1} alt="" width={45} />
                  </div>
                </div>
                <h3 className='mb-0 py-2 mt-2'>
                  CREATE YOUR PORTFOLIOS
                </h3>
                <p>
                  Gofolio uses historical price data from as far back as 10 years to generate the results for your portfolio.
                </p>
              </div>
              <div className="col-12 col-md-4">
                <div className="how-icons">
                  <div className="icon">
                    <img src={how1} alt="" width={45} />
                  </div>
                </div>
                <h3 className='mb-0 py-2 mt-2'>
                  INPUT YOUR INVESTMENTS
                </h3>
                <p>
                  Input your investments by ticker symbol and allow Gofolio to generate the most efficient allocation rates based upon historical data, or input your own allocation rates to compare against Gofolio’s output.
                </p>
              </div>
              <div className="col-12 col-md-4">
                <div className="how-icons">
                  <div className="icon">
                    <img src={how1} alt="" width={45} />
                  </div>
                </div>
                <h3 className='mb-0 py-2 mt-2'>
                  REVIEW THE RESULTS
                </h3>
                <p>
                  Explore Gofolio's generated results and compare against your benchmarks and other investments or models.
                </p>
              </div>
            </div>
            <div className="side-curve-img d-none d-md-block">
              <img src={sideCurve} alt="" width={350} />
            </div>
          </div>

          <div className='home-footer'>
            <div className="logo-footer">
              <img src={footerLogo} width={120} alt="" />
            </div>
            <div className="footer-center-text">
              <p className='text-center m-auto'>
                <span className='text-decoration-underline'>Terms of use</span> <span className='mx-2'>|</span> <span className='text-decoration-underline'>Privacy policy</span> <span className='mx-2'>|</span> <span className='text-decoration-underline'>Disclosures</span>  <br />

              </p>
              <p className='text-center m-auto'>©2023 GoFolio. All rights reserved.</p>
            </div>
            <div className="footer-social">
              <div>
                <span className='d-flex my-2'>
                  <img src={mail} width={20} alt="" className='me-2' />
                  <p className='my-auto'>Help@thegofolio.com</p>
                </span>
                <span className='d-flex '>
                  <img src={facebook} width={20} alt="" className='me-2' />
                  <img src={instagram} width={20} alt="" className='me-2' />
                  <p className='m-auto'>Help@thegofolio.com</p>
                </span>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Home