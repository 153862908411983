import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import './fonts/Outfit-VariableFont_wght.ttf';
import './App.css'
import OAuthLogin from './components/view/OauthLogin';
import Logout from './components/view/Logout';
import Home from './components/view/Home';
import Pricing from './components/view/Pricing';
import FAQ from './components/view/FAQ';
import TOS from './components/view/TOS';
import Dashboard from './components/view/Dashboard';

import { loginRequest } from "./authConfig";

const authRequest = {
  ...loginRequest
};

function App() {

  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/tos" element={<TOS />} />
          <Route exact path="/login" element={<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest} ><OAuthLogin /></MsalAuthenticationTemplate>} />
          <Route path="/logout" element={<Logout />} />
          <Route exact path="/app" element={<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest} ><Dashboard /></MsalAuthenticationTemplate>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
