import React, { useEffect, useState } from 'react';
import Nav from '../common/Nav';
import { InfoTip } from '../common/InfoTip';
import VirtualizedAutocomplete from '../common/VirtualizedAutocomplete';
import logo from '../../dist/images/GOFOLIO_WHITE.svg';
import { _newPortfolioShowSecurities, _newPortfolioSecurityIds, _newPortfolioWeights } from '../common/data';
import { createPortfolioRun, updateUserDisclaimers, getUserData } from '../../api/api';
import { useNavigate } from "react-router-dom";
import { TermsAccordion } from '../common/TermsDisclosures';

const NewPortfolioForm = (props) => {
  const navigate = useNavigate();
  const [newPortfolioAddErrorMsg, setNewPortfolioAddErrorMsg] = useState('');
  const [newPortfolioShowSecurities, setNewPortfolioShowSecurities] = useState(_newPortfolioShowSecurities);
  const [newPortfolioSecurityIds, setNewPortfolioSecurityIds] = useState(_newPortfolioSecurityIds);
  const [newPortfolioSecuritiesName1, setNewPortfolioSecuritiesName1] = useState("");
  const [newPortfolioSecuritiesName2, setNewPortfolioSecuritiesName2] = useState("");
  const [newPortfolioSecuritiesName3, setNewPortfolioSecuritiesName3] = useState("");
  const [newPortfolioSecuritiesName4, setNewPortfolioSecuritiesName4] = useState("");
  const [newPortfolioSecuritiesName5, setNewPortfolioSecuritiesName5] = useState("");
  const [newPortfolioSecuritiesName6, setNewPortfolioSecuritiesName6] = useState("");
  const [newPortfolioSecuritiesName7, setNewPortfolioSecuritiesName7] = useState("");
  const [newPortfolioSecuritiesName8, setNewPortfolioSecuritiesName8] = useState("");
  const [newPortfolioWeights, setNewPortfolioWeights] = useState(_newPortfolioWeights);
  const [newPortfolioName, setNewPortfolioName] = useState('');
  const [newPortfolioShowWeights, setNewPortfolioShowWeights] = useState(true);
  const [newPortfolioShowRemoveSecurityButton, setNewPortfolioShowRemoveSecurityButton] = useState(true);
  const [newPortfolioShowAddSecurityButton, setNewPortfolioShowAddSecurityButton] = useState(true);
  const [newPortfolioAcceptDisclaimer, setNewPortfolioAcceptDisclaimer] = useState(true);
  const [newPortfolioAlwaysAcceptDisclaimer, setNewPortfolioAlwaysAcceptDisclaimer] = useState(true);

  // field validator indicators
  const [newPortfolioUnusedRowsValid, setNewPortfolioUnusedRowsValid] = useState(true);
  const [newPortfolioNameValid, setNewPortfolioNameValid] = useState(true);
  const [newPortfolioWeightsNumericalValid, setNewPortfolioWeightsNumericalValid] = useState(true);
  const [newPortfolioWeightsSumValid, setNewPortfolioWeightsSumValid] = useState(true);
  const [newPortfolioWeightsInvalidCTA, setNewPortfolioWeightsInvalidCTA] = useState([]);
  const [newPortfolioWeightsBoundsValid, setNewPortfolioWeightsBoundsValid] = useState(true);
  const [newPortfolioSecuritiesValid, setNewPortfolioSecuritiesValid] = useState(true);
  const [newPortfolioAcceptDisclaimerValid, setNewPortfolioAcceptDisclaimerValid] = useState(true);

  const [canSubmit, setCanSubmit] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSetName = (data) => {
    setNewPortfolioName(data.target.value)
  };
  const validateNameInput = (newPortfolioName) => {
    if (!newPortfolioName || newPortfolioName.length > 50 || newPortfolioName.length < 3) {
      setNewPortfolioNameValid(false);
      return;
    }
    setNewPortfolioNameValid(true);
  };
  const handleSetNewPortfolioSecurities = (index, event, data) => {
    let x = {}
    // data is null if user clears input
    x[index] = data ? data.id : 0
    setNewPortfolioSecurityIds({ ...newPortfolioSecurityIds, ...x })
    let symbol = data ? data.symbol : ''
    if (index === 1) {
      setNewPortfolioSecuritiesName1(symbol);
    } else if (index === 2) {
      setNewPortfolioSecuritiesName2(symbol);
    } else if (index === 3) {
      setNewPortfolioSecuritiesName3(symbol);
    } else if (index === 4) {
      setNewPortfolioSecuritiesName4(symbol);
    } else if (index === 5) {
      setNewPortfolioSecuritiesName5(symbol);
    } else if (index === 6) {
      setNewPortfolioSecuritiesName6(symbol);
    } else if (index === 7) {
      setNewPortfolioSecuritiesName7(symbol);
    } else {
      setNewPortfolioSecuritiesName8(symbol);
    }
  };
  const handleSetNewPortfolioShowSecurities = () => {
    // show the first one we find that is not shown, skipping 1,2,3 b/c at least 3 are required
    let index = 0;
    for (let i = 4; i <= Object.keys(newPortfolioShowSecurities).length; i++) {
      if (!newPortfolioShowSecurities[i]) {
        index = i;
        break;
      }
    }
    setNewPortfolioShowSecurities(prevState => ({
      ...prevState,
      [index]: true,
    }));

    setNewPortfolioShowRemoveSecurityButton(true);

    // only need to show if we didn't just show the 8th security
    let showAddSecurityButton = true;
    if (index >= 8) {
      showAddSecurityButton = false;
      setNewPortfolioShowAddSecurityButton(showAddSecurityButton);
    }
  };
  const handleSetNewPortfolioRemoveSecurities = () => {
    let index = Object.keys(newPortfolioShowSecurities).length;
    for (let i = Object.keys(newPortfolioShowSecurities).length; i >= 4; i--) {
      if (newPortfolioShowSecurities[i]) {
        index = i;
        break;
      }
    }
    setNewPortfolioWeights(prevState => ({
      ...prevState,
      [index]: 0,
    }));
    if (index === 3) {
      setNewPortfolioSecuritiesName3("");
    } else if (index === 4) {
      setNewPortfolioSecuritiesName4("");
    } else if (index === 5) {
      setNewPortfolioSecuritiesName5("");
    } else if (index === 6) {
      setNewPortfolioSecuritiesName6("");
    } else if (index === 7) {
      setNewPortfolioSecuritiesName7("");
    } else {
      setNewPortfolioSecuritiesName8("");
    }
    // console.log(`handleSetNewPortfolioRemoveSecurities index: ${index}}`);
    setNewPortfolioSecurityIds(prevState => ({
      ...prevState,
      [index]: 0,
    }));

    setNewPortfolioShowSecurities(prevState => ({
      ...prevState,
      [index]: false,
    }));
    if (index === 4) {
      setNewPortfolioShowRemoveSecurityButton(false);
    } else {
      setNewPortfolioShowRemoveSecurityButton(true);
    }
    setNewPortfolioShowAddSecurityButton(true);
  };
  const handleSetNewPortfolioWeights = (index, data) => {
    var val = parseInt(data.target.value);
    if (isNaN(val)) {
      val = 0;
    }
    setNewPortfolioWeights(prevState => ({
      ...prevState,
      [index]: val,
    }));
  };
  const fetchUserData = async () => {
    var res = {}
    try {
      res = await getUserData();
    } catch (err) {
      navigate("/login");
    }
    if (res.status === 200) {
      setNewPortfolioAlwaysAcceptDisclaimer(res.data?.newPortfolioAlwaysAcceptDisclaimer);
      if (res.data?.newPortfolioAlwaysAcceptDisclaimer) {
        setNewPortfolioAcceptDisclaimer(true);
      }
    } else {
      navigate("/login");
    }
  };
  const validateNewPortfolioWeights = () => {
    let sum = 0;
    let foundZero = false;
    let weightsNumericalValid = true;
    let weightsBoundsValid = true;
    // eslint-disable-next-line no-unused-vars
    for (const [id, value] of Object.entries(newPortfolioWeights)) {
      // if its shown to the user
      if (newPortfolioShowSecurities[id]) {
        let val = parseInt(value);
        if (isNaN(val)) {
          weightsNumericalValid = false;
        }

        if (val <= 0 || val > 50 || val < 5) {
          weightsBoundsValid = false;
        }
        if (val <= 0) {
          foundZero = true;
          // console.log('validateNewPortfolioWeights setNewPortfolioUnusedRowsValid false')
        }
        sum += val
      }
    }
    if (sum !== 100) {
      setNewPortfolioWeightsSumValid(false);
      let remaining = 100 - sum
      setNewPortfolioWeightsInvalidCTA(`Your weight total is at ${sum}, ${remaining > 0 ? `so you have ${remaining} remaining to allocate` : `so you need to reduce your total by ${Math.abs(remaining)}`}`);
    } else {
      setNewPortfolioWeightsInvalidCTA('');
      setNewPortfolioWeightsSumValid(true);
    }
    setNewPortfolioWeightsNumericalValid(weightsNumericalValid);
    setNewPortfolioWeightsBoundsValid(weightsBoundsValid);
    setNewPortfolioUnusedRowsValid(!foundZero);
  };
  // validateSecurities returns data to be used in submitPortfolio
  const validateSecurities = () => {
    let ret = {
      'secIdsGood': true,
      'weightsGood': true,
      'noUnusedRows': true,
      'data': {}
    };
    // VALIDATE: check for duplicates
    // VALIDATE: somehow provided non-numerical weight
    for (const [key, value] of Object.entries(newPortfolioShowSecurities)) {
      // check newPortfolioShowSecurities for which ones are shown
      // ignore ones with empty ticker name
      // value == if visible
      if (value) {
        // get corresponding ticker value
        let secId = newPortfolioSecurityIds[key]; //JSON.stringify().trim();
        if (!(typeof secId === 'string') || !(secId instanceof String)) {
          secId = JSON.stringify(secId).trim();
        }
        if (secId) {
          // checks for dupe securities
          if (ret['data'].hasOwnProperty(secId)) {
            ret['secIdsGood'] = false;
          } else {
            // ignore the value if the name is blank, means the input was wiped out
            if (key === "1" && newPortfolioSecuritiesName1.trim() === "") {
              ret['noUnusedRows'] = false;
              continue;
            } else if (key === "2" && newPortfolioSecuritiesName2.trim() === "") {
              ret['noUnusedRows'] = false;
              continue;
            } else if (key === "3" && newPortfolioSecuritiesName3.trim() === "") {
              ret['noUnusedRows'] = false;
              continue;
            } else if (key === "4" && newPortfolioSecuritiesName4.trim() === "") {
              ret['noUnusedRows'] = false;
              continue;
            } else if (key === "5" && newPortfolioSecuritiesName5.trim() === "") {
              ret['noUnusedRows'] = false;
              continue;
            } else if (key === "6" && newPortfolioSecuritiesName6.trim() === "") {
              ret['noUnusedRows'] = false;
              continue;
            } else if (key === "7" && newPortfolioSecuritiesName7.trim() === "") {
              ret['noUnusedRows'] = false;
              continue;
            } if (key === "8" && newPortfolioSecuritiesName8.trim() === "") {
              ret['noUnusedRows'] = false;
              continue;
            }
            let val = 0;
            // get corresponding weight value if visible
            if (newPortfolioShowWeights) {
              val = parseInt(newPortfolioWeights[key]);
              if (typeof val === "undefined") {
                val = 0;
                ret['weightsGood'] = false;
              }
            }
            ret['data'][secId] = val;
          }
        }
      }
    }
    // VALIDATE: make sure there are at least three securities
    if (Object.keys(ret['data']).length < 3) {
      ret['secIdsGood'] = false;
    }
    return ret;
  };
  const resetNewPortfolioState = (clearData) => {
    setNewPortfolioNameValid(true);
    setNewPortfolioSecuritiesValid(true);
    setNewPortfolioUnusedRowsValid(true);
    if (clearData) {
      setNewPortfolioName("");
      setNewPortfolioShowSecurities({ ..._newPortfolioShowSecurities });
      setNewPortfolioShowRemoveSecurityButton(true);
      setNewPortfolioSecurityIds({ ..._newPortfolioSecurityIds });
      setNewPortfolioSecuritiesName1("");
      setNewPortfolioSecuritiesName2("");
      setNewPortfolioSecuritiesName3("");
      setNewPortfolioSecuritiesName4("");
      setNewPortfolioSecuritiesName5("");
      setNewPortfolioSecuritiesName6("");
      setNewPortfolioSecuritiesName7("");
      setNewPortfolioSecuritiesName8("");
      setNewPortfolioWeights({ ..._newPortfolioWeights });
    }
  };
  const submitPortfolio = () => {
    if (!canSubmit) {
      return;
    }

    let ret = validateSecurities();
    if (!ret['secIdsGood']) {
      setNewPortfolioSecuritiesValid(false);
    }
    if (!ret['weightsGood']) {
      setNewPortfolioWeightsNumericalValid(false);
    }
    if (!ret['noUnusedRows']) {
      setNewPortfolioUnusedRowsValid(false);
    }
    if (!ret['secIdsGood'] || !ret['weightsGood'] || !ret['noUnusedRows']) {
      return;
    }

    let secIdWeightMap = ret['data'];
    let secIds = [];
    let weights = [];
    let secIdsStrings = Object.keys(secIdWeightMap);
    // VALIDATE: somehow provided non-integer values for secId
    for (var i = 0; i < secIdsStrings.length; i++) {
      let intVal = parseInt(secIdsStrings[i]);
      if (isNaN(intVal)) {
        setNewPortfolioSecuritiesValid(false);
        return;
      }
      secIds.push(intVal);
      weights.push(secIdWeightMap[secIdsStrings[i]]);
    }

    let reqData = {
      'name': newPortfolioName,
      'type': newPortfolioShowWeights ? 'userDefined' : 'gofolio',
      'securityIds': secIds,
      'weights': newPortfolioShowWeights ? weights : [],
    }
    setIsSubmitting(true);
    return createPortfolioRun(reqData).then((res) => {
      if (res.status === 200) {
        let ret = {
          id: res.data.id,
          name: newPortfolioName,
          type: newPortfolioShowWeights ? 'userDefined' : 'gofolio',
          status: "Processing",
        }
        props.handleAddPortfolio(res.data.id, ret);
        resetNewPortfolioState(true);
        setIsSubmitting(false);
        props.setSpotlightPortfolio(ret);
        props.setSpotlightTitle('Last Calculated');
        props.setPage('portfolios');
      } else {
        // no add portfolio, just show err msg
        setNewPortfolioAddErrorMsg(res.data.msg);
        setIsSubmitting(false);
      }
    });
  };
  const handleSetNewPortfolioAlwaysAcceptDisclaimer = (data) => {
    setNewPortfolioAlwaysAcceptDisclaimer(data.target.checked);
    return updateUserDisclaimers({
      'newPortfolioAlwaysAcceptDisclaimer': data.target.checked,
    });
  };

  useEffect(() => {
    validateNameInput(newPortfolioName);
  }, [newPortfolioName]);

  useEffect(() => {
    validateNewPortfolioWeights();
  }, [newPortfolioWeights]);

  useEffect(() => {
    if (!newPortfolioAcceptDisclaimer) {
      setNewPortfolioAcceptDisclaimerValid(false);
    }
  }, [newPortfolioAcceptDisclaimer]);

  useEffect(() => {
    let ret = validateSecurities();
    // console.log(`validateSecurities ret: ${JSON.stringify(ret)}`)
    if (!ret['secIdsGood']) {
      setNewPortfolioSecuritiesValid(false);
    } else {
      setNewPortfolioSecuritiesValid(true);
    }
    if (!ret['weightsGood']) {
      setNewPortfolioWeightsNumericalValid(false);
      // console.log('validateSecurities weightsGood false')
    } else {
      setNewPortfolioWeightsNumericalValid(true);
    }
    if (!ret['noUnusedRows']) {
      setNewPortfolioUnusedRowsValid(false);
      // console.log('validateSecurities setNewPortfolioUnusedRowsValid false')
    } else {
      setNewPortfolioUnusedRowsValid(true);
    }
  }, [newPortfolioSecurityIds, newPortfolioWeights, newPortfolioShowSecurities]);

  useEffect(() => {
    if (newPortfolioShowWeights && (!newPortfolioWeightsNumericalValid || !newPortfolioWeightsSumValid || !newPortfolioWeightsBoundsValid)) {
      setCanSubmit(false);
      return;
    }
    if (newPortfolioUnusedRowsValid && newPortfolioNameValid && newPortfolioSecuritiesValid && newPortfolioAcceptDisclaimerValid) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [newPortfolioUnusedRowsValid, newPortfolioNameValid, newPortfolioWeightsNumericalValid, newPortfolioWeightsSumValid, newPortfolioWeightsBoundsValid, newPortfolioSecuritiesValid, newPortfolioAcceptDisclaimerValid]);

  useEffect(() => { fetchUserData(); }, []);

  return (
    <>
      <Nav heading="Gofolio Calculator" setPage={props.setPage} page={props.page} name={props.name} />

      <div className="container add-portfolio pb-2">

        <div className="text-center py-3">
          <img src={logo} width="228.996px" height="93.9961" alt='' />
        </div>

        <div className="card add-portfolio-card mx-auto w-95 w-xs-100">
          <div className="card-body">
            <div className="container text-center px-1 pt-4 pb-3">
              <form>
                <div className="row">
                  <div className="col">
                    <div className="text-center">
                      <h3><InfoTip text="Provide a name between 3 and 50 characters so you can identify this portfolio later." placement="top"></InfoTip>Portfolio Name</h3>
                      {newPortfolioNameValid ? null : <p className="error-msg">Provide a name between 3 and 50 characters.</p>}
                    </div>
                    <div className="input-group mb-3 text-center">
                      <input type="text" id="portfolio-name" className="form-control" placeholder="Enter a name or description" onChange={handleSetName} value={newPortfolioName} />
                    </div>

                    <div className="row text-center">
                      <h3>Portfolio Type</h3>
                    </div>
                    <div className="row text-center">
                      <div className="col mt-3">
                        <InfoTip text="Input your own portfolio weights to see how it would have performed over time. These portfolios will have a solid dark blue background." placement="top"></InfoTip>
                        <button type="button" className={`btn button-ol-dark ${newPortfolioShowWeights ? 'active' : ''}`} onClick={() => setNewPortfolioShowWeights(true)}>USER DEFINED</button>
                      </div>
                      <div className="col my-3">
                        <InfoTip text="Choose your securities to have Gofolio calculate the most optimal weights. These portfolios will have a dual color background." placement="top"></InfoTip>
                        <button type="button" className={`btn button-ol-dark ${newPortfolioShowWeights ? '' : 'active'}`} onClick={() => setNewPortfolioShowWeights(false)}>GOFOLIO CALCULATED</button>
                      </div>
                    </div>
                    <div className="row mt-5">

                      <TermsAccordion></TermsAccordion>

                      {newPortfolioAcceptDisclaimerValid ? null : <div className="row text-center"><div className="col"><p className="error-msg">Please accept the terms and disclosures.</p></div></div>}

                      <div className="row mt-3 sign-in-label text-center">
                        <div className="col">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="acceptDisclaimers" checked={newPortfolioAcceptDisclaimer} value={newPortfolioAcceptDisclaimer} onChange={setNewPortfolioAcceptDisclaimer} />
                            <label className="form-check-label accept-disclaimer-text" htmlFor="acceptDisclaimers">
                              Accept Terms and Disclosures
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="alwaysAcceptDisclaimers" checked={newPortfolioAlwaysAcceptDisclaimer} value={newPortfolioAlwaysAcceptDisclaimer} onChange={handleSetNewPortfolioAlwaysAcceptDisclaimer} />
                            <label className="form-check-label accept-disclaimer-text" htmlFor="alwaysAccetpDisclaimers">
                              <strong>Always</strong> Accept Terms and Disclosures
                            </label>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="col">
                    <div className="row">
                      {newPortfolioSecuritiesValid ? null : <p className="error-msg">Choose at least 3 unique security tickers</p>}
                      {newPortfolioShowWeights ? <></> : <h3>Securities</h3>}
                    </div>

                    {newPortfolioShowWeights ?
                      <>
                        {newPortfolioWeightsInvalidCTA ? <div className="row">
                          <div className="row"><div className="col"><p className="error-msg">{newPortfolioWeightsInvalidCTA}</p></div></div>
                        </div> : <></>}

                        <div className="row">
                          <div className="col-6">Securities</div>
                          <div className="col-6"><InfoTip text="The percentage of this portfolio's funds allocated to a given security." placement="top"></InfoTip>Weights (%)</div>
                          <div className="col-sm-2"></div>
                        </div>
                        <div className="row">
                          <div className="col-8 col-sm-8">
                            <VirtualizedAutocomplete
                              id="add-security-w-1"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(1, e, data)}
                            />
                          </div>
                          <div className="col-4 col-sm-2">
                            <input
                              className="form-control autocomplete-input"
                              type="tel"
                              pattern="[0-9]*"
                              onChange={(data) => handleSetNewPortfolioWeights(1, data)}
                              value={newPortfolioWeights[1]}
                            /></div>
                          <div className="col-sm-2"></div>
                        </div>
                        <div className="row">
                          <div className="col-8 col-sm-8">
                            <VirtualizedAutocomplete
                              id="add-security-w-2"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(2, e, data)}
                            />
                          </div>
                          <div className="col-4 col-sm-2">
                            <input
                              className="form-control autocomplete-input"
                              type="tel"
                              pattern="[0-9]*"
                              onChange={(data) => handleSetNewPortfolioWeights(2, data)}
                              value={newPortfolioWeights[2]}
                            /></div>
                          <div className="col-sm-2"></div>
                        </div>
                        {newPortfolioShowSecurities[3] ? <div className="row">
                          <div className="col-8 col-sm-8">
                            <VirtualizedAutocomplete
                              id="add-security-w-3"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(3, e, data)}
                            />
                          </div>
                          <div className="col-4 col-sm-2">
                            <input
                              className="form-control autocomplete-input"
                              type="tel"
                              pattern="[0-9]*"
                              onChange={(data) => handleSetNewPortfolioWeights(3, data)}
                              value={newPortfolioWeights[3]}
                            /></div>
                          <div className="col-sm-2"></div>
                        </div> : null}
                        {newPortfolioShowSecurities[4] ? <div className="row justify-content-center">
                          <div className="col-8 col-sm-8">
                            <VirtualizedAutocomplete
                              id="add-security-w-4"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(4, e, data)}
                            />
                          </div>
                          <div className="col-4 col-sm-2">
                            <input
                              className="form-control autocomplete-input"
                              type="tel"
                              pattern="[0-9]*"
                              onChange={(data) => handleSetNewPortfolioWeights(4, data)}
                              value={newPortfolioWeights[4]}
                            /></div>
                          <div className="col-sm-2"></div>
                        </div> : null}
                        {newPortfolioShowSecurities[5] ? <div className="row justify-content-center">
                          <div className="col-8 col-sm-8">
                            <VirtualizedAutocomplete
                              id="add-security-w-5"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(5, e, data)}
                            />
                          </div>
                          <div className="col-4 col-sm-2">
                            <input
                              className="form-control autocomplete-input"
                              type="tel"
                              pattern="[0-9]*"
                              onChange={(data) => handleSetNewPortfolioWeights(5, data)}
                              value={newPortfolioWeights[5]}
                            /></div>
                          <div className="col-sm-2"></div>
                        </div> : null}
                        {newPortfolioShowSecurities[6] ? <div className="row justify-content-center">
                          <div className="col-8 col-sm-8">
                            <VirtualizedAutocomplete
                              id="add-security-w65"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(6, e, data)}
                            />
                          </div>
                          <div className="col-4 col-sm-2">
                            <input
                              className="form-control autocomplete-input"
                              type="tel"
                              pattern="[0-9]*"
                              onChange={(data) => handleSetNewPortfolioWeights(6, data)}
                              value={newPortfolioWeights[6]}
                            /></div>
                          <div className="col-sm-2"></div>
                        </div> : null}
                        {newPortfolioShowSecurities[7] ? <div className="row justify-content-center">
                          <div className="col-8 col-sm-8">
                            <VirtualizedAutocomplete
                              id="add-security-w-7"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(7, e, data)}
                            />
                          </div>
                          <div className="col-4 col-sm-2">
                            <input
                              className="form-control autocomplete-input"
                              type="tel"
                              pattern="[0-9]*"
                              onChange={(data) => handleSetNewPortfolioWeights(7, data)}
                              value={newPortfolioWeights[7]}
                            /></div>
                          <div className="col-sm-2"></div>
                        </div> : null}
                        {newPortfolioShowSecurities[8] ? <div className="row justify-content-center">
                          <div className="col-8 col-sm-8">
                            <VirtualizedAutocomplete
                              id="add-security-w-8"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(8, e, data)}
                            />
                          </div>
                          <div className="col-4 col-sm-2">
                            <input
                              className="form-control autocomplete-input"
                              type="tel"
                              pattern="[0-9]*"
                              onChange={(data) => handleSetNewPortfolioWeights(8, data)}
                              value={newPortfolioWeights[8]}
                            /></div>
                          <div className="col-sm-2"></div>
                        </div> : null}
                      </>
                      :
                      <>
                        <div className="row justify-content-center gy-2 pt-2">
                          <div className="col-8">
                            <VirtualizedAutocomplete
                              id="add-security-1"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(1, e, data)}
                            />
                          </div><div className="col-8">
                            <VirtualizedAutocomplete
                              id="add-security-2"
                              options={props.securities}
                              onChange={(e, data) => handleSetNewPortfolioSecurities(2, e, data)}
                            />
                          </div>
                          <div className="col-8">
                            {newPortfolioShowSecurities[3] ?
                              <VirtualizedAutocomplete
                                id="add-security-3"
                                options={props.securities}
                                onChange={(e, data) => handleSetNewPortfolioSecurities(3, e, data)}
                              />
                              : null}
                          </div>
                        </div>
                        <div className="row justify-content-center gy-2 pt-2">
                          <div className="col-8">
                            {newPortfolioShowSecurities[4] ?
                              <VirtualizedAutocomplete
                                id="add-security-4"
                                options={props.securities}
                                onChange={(e, data) => handleSetNewPortfolioSecurities(4, e, data)}
                              />
                              : null}
                          </div>
                          <div className="col-8">
                            {newPortfolioShowSecurities[5] ?
                              <VirtualizedAutocomplete
                                id="add-security-5"
                                options={props.securities}
                                onChange={(e, data) => handleSetNewPortfolioSecurities(5, e, data)}
                              />
                              : null}
                          </div><div className="col-8">
                            {newPortfolioShowSecurities[6] ?
                              <VirtualizedAutocomplete
                                id="add-security-6"
                                options={props.securities}
                                onChange={(e, data) => handleSetNewPortfolioSecurities(6, e, data)}
                              />
                              : null}
                          </div>
                        </div>
                        <div className="row justify-content-center gy-2 pt-2">
                          <div className="col-8">
                            {newPortfolioShowSecurities[7] ?
                              <VirtualizedAutocomplete
                                id="add-security-7"
                                options={props.securities}
                                onChange={(e, data) => handleSetNewPortfolioSecurities(7, e, data)}
                              />
                              : null}
                          </div><div className="col-8">
                            {newPortfolioShowSecurities[8] ?
                              <VirtualizedAutocomplete
                                id="add-security-8"
                                options={props.securities}
                                onChange={(e, data) => handleSetNewPortfolioSecurities(8, e, data)}
                              />
                              : null}
                          </div>
                        </div>
                      </>}


                    <div className="row justify-content-center py-3">
                      {newPortfolioShowAddSecurityButton ?
                        <div className="col-xl-2">
                          <button type="button" className="btn-circle btn-lg" style={{ paddingTop: '5px' }} onClick={handleSetNewPortfolioShowSecurities}><i className="fa fa-plus"></i></button>
                        </div>
                        : null}
                      <div className={`col-xl-2 ${newPortfolioShowRemoveSecurityButton ? "" : "d-none"}`}>
                        <button type="button" className="btn-circle btn-lg" style={{ paddingTop: '5px' }} onClick={handleSetNewPortfolioRemoveSecurities}><i className="fa fa-minus"></i></button>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>

            {!newPortfolioUnusedRowsValid && (newPortfolioShowSecurities[4] || newPortfolioShowSecurities[5] || newPortfolioShowSecurities[6] || newPortfolioShowSecurities[7]) ? <div className="row">
              <div className="row"><div className="col"><p className="error-msg">Remove any un-used rows with with minus (-) button above.</p></div></div>
            </div> : <></>}

            {newPortfolioShowWeights && !newPortfolioWeightsNumericalValid ? <div className="row">
              <div className="row"><div className="col"><p className="error-msg">Only provide numeric values for weights.</p></div></div>
            </div> : <></>}

            {newPortfolioShowWeights && !newPortfolioWeightsBoundsValid ? <div className="row">
              <div className="row"><div className="col"><p className="error-msg">Weights must be whole numbers between 5 and 50.</p></div></div>
            </div> : <></>}

            {newPortfolioShowWeights && !newPortfolioWeightsSumValid ? <div className="row">
              <div className="row"><div className="col"><p className="error-msg">Weights must sum to 100.</p></div></div>
            </div> : <></>}

            <div className="row text-center">
              <div className="col mt-3">
                <button type="button" className="btn button-ol-dark" onClick={() => resetNewPortfolioState(true)}>RESET</button>
              </div>
              <div className="col mt-3">
                <button type="button" className="btn button-ol-dark" onClick={submitPortfolio} disabled={!canSubmit}>{ isSubmitting ? <span className="spinner-grow spinner-grow-sm" style={{ color: '#026773' }}></span> : 'GO!' }</button>
              </div>
            </div>

            {newPortfolioAddErrorMsg ? <div className="row text-center pt-3"><div className="col"><div>There was an error creating this portfolio. :(</div>{newPortfolioAddErrorMsg}</div></div> : null}

            <div className="pt-4 text-center">
              <p className="copyright-text">&copy; 2023 Gofolio. All rights reserved.</p>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default NewPortfolioForm;
