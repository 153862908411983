import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { userLogOut } from '../../api/api';


const Logout = () => {
    const { instance } = useMsal();

    useEffect(() => {
        async function logout() {
            userLogOut();
            let a = instance.getActiveAccount();
            await instance.logoutRedirect({
                account: a,
            });
        }
        logout();
    }, [instance])

    return (
        <div class="container">
            <div className="row align-items-center justify-content-center text-center pt-5">
                <div className="col">
                    <div className="spinner-grow" role="status">
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Logout;