import { LoadingChart } from "./Chart";
import card from '../../dist/icons/GoFolio_PORTFOLIO_CARD_ICON.svg'

const Box = ({
  id,
  name,
  type,
  topExpectedReturn,
  portfolioClickHook,
  portfolioDeleteHook,
}) => {
  const portfolioName = name.length > 34 ? name.substring(0, 34) + '...' : name
  return (
    <div key={id} className={`p-3 portfolio-wrapper text-start ${type === "userDefined" ? "userDefined" : "gofolio"}`}>
      <div className="portfolio-box" id={id}>
        <div style={{ cursor: "pointer" }} onClick={() => portfolioClickHook(id)}>
          <div className="d-flex">
            <div className='d-flex flex-column' style={{ wordBreak: 'break-all'}}>
              <p className='portfolio-text-name'>{portfolioName}</p>
            </div>
            <div className='d-flex pt-3'>
              <img src={card} width={34} alt='' />
            </div>
          </div>
          <h2 className='percentage'>{topExpectedReturn !== null ? `${topExpectedReturn}%` : '-'}</h2>
          <p className='portfolio-text-type'>{type === "userDefined" ? "User Defined" : "Gofolio Calculated"}</p>
        </div>
        <span className="d-flex">
          <button className="buttonDetails" onClick={() => portfolioClickHook(id)}>Details</button>
          <button className="buttonX" onClick={() => portfolioDeleteHook(id)}><i className="fa-solid fa-xmark"></i></button>
        </span>
      </div>
    </div>
  )
}

const BoxPlaceHolder = (props) => {
  return (
    <div className="col-md-4 col-12 col-lg-4">
      <div className="box">
        <div className="box-top gofolio"></div>
        <div className='box-inner-text mx-1'>
          <div className="row">
            <div className="col">
              <p>{props.text}</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 pb-2">
                <div className="mx-auto my-auto" style={{ opacity: .6 }}>
                  <LoadingChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Box, BoxPlaceHolder };