import React, { useEffect, useReducer } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { initialState, reducer } from './UserDataState';
import { getUserData, userSelfDelete, updateUserData } from '../../api/api';
import logo from '../../dist/images/GOFOLIO_NAVY.svg';

export const UserData = () => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleDelete = async () => {
        if (state.showConfirmDeleteModal) {
            await userSelfDelete();
            navigate("/logout");
        } else {
            dispatch({
                type: 'showConfirmDeleteModal',
                data: true
            });
        }
    };

    const fetchUserData = async () => {
        dispatch({
            type: 'isLoading',
            data: true,
        });
        let res = await getUserData();
        if (res.status === 200) {
            dispatch({
                type: 'setUserData',
                data: res.data,
            });
        } else {
            dispatch({
                type: 'error',
                data: res.data,
            });
        }
        dispatch({
            type: 'isLoading',
            data: false,
        });
    };

    const setUserData = async () => {
        dispatch({
            type: 'isUpdating',
            data: true,
        });
        let res = await updateUserData({
            newPortfolioAlwaysAcceptDisclaimer: state.newPortfolioAlwaysAcceptDisclaimer,
            acceptEmails: state.acceptEmails,
        });
        if (res.status === 200) {
            setTimeout(function () {
                dispatch({
                    type: 'setUserData',
                    data: {
                        newPortfolioAlwaysAcceptDisclaimer: state.newPortfolioAlwaysAcceptDisclaimer,
                        acceptEmails: state.acceptEmails,
                    },
                });
            }, 3000);
        } else {
            dispatch({
                type: 'error',
                data: {
                    error: res.data,
                }
            });
        }
    };

    const saveChangesDisabled = () => {
        if (state.isUpdating) {
            return true;
        }
        if (state.origUserData.acceptEmails === state.acceptEmails && state.origUserData.newPortfolioAlwaysAcceptDisclaimer === state.newPortfolioAlwaysAcceptDisclaimer) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <div className='account-container'>
            <Modal show={state.showConfirmDeleteModal}>
                <div className="text-center py-3">
                    <img src={logo} width="228.996px" height="93.9961" alt='' />
                </div>

                <div className="card add-portfolio-card mx-auto w-75 w-xs-100 mb-2">
                    <div className="card-body mb-2">
                        <div className="container text-center px-1 pt-1">
                            <>
                                <div className="text-center">
                                    <h7>Delete my account</h7>
                                </div>
                                <div className="text-center">
                                    <p>Continuing will log you out of your current session. All existing portfolios you've created will be deleted and cannot be retrieved.</p>
                                    <p>Your contact information will be deleted within 30 days unless you login again.</p>
                                </div>
                                <div className="row text-center">
                                    <div className="col mt-3">
                                        <button className="btn button-dark" onClick={() => dispatch({
                                            type: 'showConfirmDeleteModal',
                                            data: false
                                        })}>CANCEL</button>
                                    </div>
                                    <div className="col mt-3">
                                        <button type="button" className="btn button-ol-dark" onClick={handleDelete}>DELETE & LOG OUT</button>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="email-box-wrapper my-4 mx-2">
                <div className="bordertag"></div>
                <div className="d-flex pb-1">
                    <p>Account Settings</p>
                    <button
                        onClick={setUserData}
                        disabled={saveChangesDisabled()}
                    >
                        SAVE CHANGES
                    </button>
                </div>
                {state.isLoading ? <div className="row text-center">
                    <div className="col">
                        <div className="spinner-grow" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </div>
                </div> : <>
                    <div className="form-check mb-4 pb-1 mt-3" style={{ borderBottom: "3px solid #cecece" }}>
                        <div className="d-md-flex" style={{ justifyContent: 'space-between' }}>
                            <div>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={state.acceptEmails}
                                    id="accept-emails-checkbox"
                                    onChange={() => {
                                        dispatch({
                                            type: 'acceptEmails',
                                            data: !state.acceptEmails,
                                        });
                                    }}
                                />
                                <label className="form-check-label" htmlFor="accept-emails-checkbox">
                                    Enable Emails
                                </label>
                            </div>
                            <div>
                                Updates on the GoFolio's new features, products, and more.
                            </div>
                        </div>
                    </div>
                    <div className="form-check mb-4 pb-1 mt-3" style={{ borderBottom: "3px solid #cecece" }}>
                        <div className="d-md-flex" style={{ justifyContent: 'space-between' }}>
                            <div>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={state.newPortfolioAlwaysAcceptDisclaimer}
                                    onChange={() => {
                                        dispatch({
                                            type: 'newPortfolioAlwaysAcceptDisclaimer',
                                            data: !state.newPortfolioAlwaysAcceptDisclaimer,
                                        });
                                    }}
                                    id="accept-disclaimers-checkbox"
                                />
                                <label className="form-check-label" htmlFor="accept-disclaimers-checkbox">
                                    Always Accept Disclaimers
                                </label>
                            </div>
                            <div>
                                Automatically accept Gofolio's Terms of Service while creating a new portfolio.
                            </div>
                        </div>
                    </div>
                    <div className="form-check mb-4 pb-1 mt-3">
                        <div className="d-md-flex" style={{ justifyContent: 'center' }}>
                            <div>
                                <button type="button" style={{ width: '100%' }} className="btn button-ol-dark" onClick={() => handleDelete()}>DELETE MY ACCOUNT</button>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </div >

    )
}
